import { Browser } from "@capacitor/browser";
import { Capacitor } from "@capacitor/core";
import { Directory, Filesystem } from "@capacitor/filesystem";
import { Share } from "@capacitor/share";
import moment from "moment";
import React from "react";

export const trimFileExtension = (fileName: string) =>
  fileName.split(".").slice(0, -1).join(".");

export const getFileExtension = (fileName: string) =>
  fileName.split(".").slice(-1);

const toDataURL = async (url: string) => {
  const blob = await fetch(url).then(response => response.blob());
  return URL.createObjectURL(blob);
};

const blobToBase64 = (blob: Blob): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      if (reader.result) {
        resolve(reader.result.toString());
      } else {
        reject(new Error(`Cannot read blob file: ${reader.error?.message}`));
      }
    };
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });

export const useDownloadFile = () => {
  const urlRef = React.useRef<string | null>(null);
  const revokeFileUrl = React.useRef<() => void>();
  revokeFileUrl.current = () => {
    if (urlRef.current) {
      try {
        URL.revokeObjectURL(urlRef.current);
      } catch (e) {
        // nothing to do
      } finally {
        urlRef.current = null;
      }
    }
  };
  const [downloading, setDownloading] = React.useState<boolean>(false);

  const downloadDesktopFromUrl = async (url: string, fileName: string) => {
    try {
      revokeFileUrl.current?.();
      const blobURL = await toDataURL(url);
      urlRef.current = blobURL;
      const a = document.createElement("a");
      a.href = blobURL;
      a.style.display = "none";
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      setTimeout(() => {
        document.body.removeChild(a);
      });
    } catch (e) {
      // fallback
      const a = document.createElement("a");
      a.href = url;
      a.style.display = "none";
      a.download = fileName;
      a.target = "_blank";
      document.body.appendChild(a);
      a.click();
      setTimeout(() => {
        document.body.removeChild(a);
      });
    }
  };

  const downloadDesktopAsBlob = async (blob: Blob, fileName: string) => {
    const fileFullPath = `${trimFileExtension(fileName)}_${moment().format(
      "YYYY-MM-DD_HH-mm-ss"
    )}.${getFileExtension(fileName)}`;
    revokeFileUrl.current?.();
    const blobURL = URL.createObjectURL(blob);
    urlRef.current = blobURL;
    const a = document.createElement("a");
    a.href = blobURL;
    a.style.display = "none";
    a.download = fileFullPath;
    document.body.appendChild(a);
    a.click();
    setTimeout(() => {
      document.body.removeChild(a);
    });
  };

  const downloadMobileFromUrl = async (url: string, fileName: string) => {
    let fileLocalPath: string | null = null;
    try {
      const fileFullPath = `${trimFileExtension(fileName)}_${moment().format(
        "YYYY-MM-DD_HH-mm-ss"
      )}.${getFileExtension(fileName)}`;

      const result = await Filesystem.downloadFile({
        url,
        method: "GET",
        dataType: "file",
        path: fileFullPath,
        recursive: true,
        progress: false,
        directory: Directory.Cache,
      });

      if (!result.path)
        throw new Error("Download failed, please try again later");

      fileLocalPath = result.path.startsWith("file://")
        ? result.path
        : `file://${result.path}`;

      try {
        await Share.share({
          url: fileLocalPath,
        });
      } catch (e) {
        // nothing to do when user cancels it
      }
    } catch (e) {
      // fallback
      try {
        await Browser.open({ url, presentationStyle: "fullscreen" });
      } catch (e) {
        const tab = window.open(url, "_system");
        if (tab) tab.focus();
      }
    }
  };

  const downloadMobileAsBlob = async (blob: Blob, fileName: string) => {
    const fileFullPath = `${trimFileExtension(fileName)}_${moment().format(
      "YYYY-MM-DD_HH-mm-ss"
    )}.${getFileExtension(fileName)}`;

    const result = await Filesystem.writeFile({
      data: await blobToBase64(blob),
      path: fileFullPath,
      recursive: true,
      directory: Directory.Cache,
      // The encoding to write the file in. If not provided, data is written as base64 encoded. Pass Encoding.UTF8 to write data as string
      encoding: undefined, // !important since data is in base64
    });

    if (!result.uri) throw new Error("Download failed, please try again later");

    const fileLocalPath = result.uri.startsWith("file://")
      ? result.uri
      : `file://${result.uri}`;

    try {
      await Share.share({
        url: fileLocalPath,
      });
    } catch (e) {
      // nothing to do when user cancels it
    }
  };

  const downloadFileFromUrl = async (url: string, fileName: string) => {
    if (downloading) return;

    setDownloading(true);
    try {
      if (Capacitor.isNativePlatform()) {
        await downloadMobileFromUrl(url, fileName);
      } else {
        await downloadDesktopFromUrl(url, fileName);
      }
    } catch (e) {
      // nothing to do
    } finally {
      setDownloading(false);
    }
  };

  const downloadFileAsBlob = async (blob: Blob, fileName: string) => {
    if (downloading) return;

    setDownloading(true);
    try {
      if (Capacitor.isNativePlatform()) {
        await downloadMobileAsBlob(blob, fileName);
      } else {
        await downloadDesktopAsBlob(blob, fileName);
      }
    } catch (e) {
      // nothing to do
    } finally {
      setDownloading(false);
    }
  };

  // revoke created object urls
  React.useEffect(() => {
    return () => {
      revokeFileUrl.current?.();
    };
  }, []);

  return { downloadFileFromUrl, downloadFileAsBlob, downloading };
};
